import { screen } from '@loft/copan-tokens';
import { spacing } from '@loft/tokens-loft';
import { createStyles, makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(()=>createStyles({
        root: {
            display: 'flex',
            marginRight: `-${spacing.inner.base02}`
        },
        toggleMenuIcon: {
            background: 'none',
            border: 'none',
            width: 40,
            height: 40,
            padding: 0,
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                cursor: 'pointer'
            },
            '&:active': {
                outline: 'none'
            },
            '&:focus': {
                outline: 'none'
            }
        },
        hiddenUntilXs: {
            display: 'flex',
            [`@media (min-width: ${screen.breakpoint.sm}px)`]: {
                display: 'none'
            }
        },
        hiddenUntilSm: {
            display: 'flex',
            [`@media (min-width: ${screen.breakpoint.md}px)`]: {
                display: 'none'
            }
        }
    }), {
    name: 'CopanHeaderMobileControls',
    index: 1
});
