import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { color, icons, screen, shape, typography } from '@loft/copan-tokens';
import { spacing } from '@loft/tokens-loft';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { defaultTransition, distanceBetweenItems } from '../Base.styles';
export const useStyles = makeStyles(()=>createStyles({
        item: {
            padding: `0 ${distanceBetweenItems.padding}`,
            position: 'relative',
            '&, & ul': {
                listStyle: 'none',
                margin: 0
            },
            '& > a, & > span': {
                fontSize: `${typography.label.md['font-size']}`
            },
            '&.hasSubItems > ul': {
                '& li': {
                    padding: 0
                },
                '& span, & a': {
                    '&:hover, &:focus': {
                        background: color.base.neutral['100'],
                        borderBottomColor: color.base.neutral['0']
                    },
                    '& svg': {
                        order: '-1',
                        marginRight: spacing.inner.base02,
                        marginLeft: 0,
                        transform: 'rotate(90deg)'
                    },
                    '& .active span': {
                        background: 'transparent',
                        borderBottomColor: color.base.neutral['0']
                    },
                    [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                        background: color.base.neutral['100'],
                        '& svg': {
                            order: '1',
                            marginRight: 0,
                            marginLeft: spacing.inner.base02,
                            transform: 'rotate(0)'
                        },
                        '&:hover, &:focus': {
                            background: color.base.neutral['100']
                        }
                    }
                },
                '& .active span': {
                    background: color.base.neutral['100'],
                    borderBottomColor: color.base.neutral['0'],
                    '& svg': {
                        transform: 'rotateY(180deg) rotate(90deg)'
                    },
                    [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                        '& svg': {
                            transform: 'rotate(0)'
                        }
                    }
                }
            },
            '&.hasSubItems > ul, &.hasSubItems > div': _object_spread_props(_object_spread({
                transform: ({ level  })=>level > 0 ? `translate(-100%, -48px)` : null,
                position: 'absolute',
                width: 'auto',
                background: color.background.light,
                top: '100%',
                left: 0,
                zIndex: 1,
                borderRadius: shape.border.radius.base,
                boxShadow: shape.shadow.xs,
                padding: `${spacing.inner.base02} 0`
            }, defaultTransition), {
                '&:not(.opened)': {
                    opacity: 0,
                    visibility: 'hidden',
                    top: '80%',
                    [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                        maxHeight: 0,
                        overflow: 'hidden',
                        transform: 'none'
                    }
                },
                '&.opened': {
                    opacity: 1,
                    [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                        maxHeight: '100vh',
                        transform: 'none',
                        '&.hasSubItems > div & span, & a': {
                            color: color.base.neutral['700']
                        }
                    }
                },
                '&:not(.opened), &.opened': {
                    [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                        position: 'initial'
                    }
                },
                [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                    borderRadius: 0,
                    boxShadow: 'initial',
                    padding: 0
                },
                '& span, & a': _object_spread({}, defaultTransition)
            }),
            '& a, & span': {
                color: 'inherit',
                textDecoration: 'none',
                outline: 'none',
                whiteSpace: 'nowrap',
                [`@media (max-width: ${screen.breakpoint.sm - 1}px)`]: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: `0 ${spacing.inner.base06}`
                },
                [`@media (min-width: ${screen.breakpoint.sm}px) and (max-width: ${screen.breakpoint.md - 1}px)`]: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: `0 ${spacing.inner.base10} 0 ${spacing.inner.base04}`
                }
            },
            '& ul a, & ul span': {
                fontSize: `${typography.label.sm['font-size']}`
            },
            '& svg': {
                marginLeft: spacing.inner.base02,
                [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                    width: icons.size.md,
                    height: icons.size.md
                }
            },
            '&.active': {
                '& > span, & > a': {
                    borderBottomColor: color.brand.primary.main,
                    [`@media (max-width: ${screen.breakpoint.sm - 1}px)`]: {
                        borderLeftColor: color.brand.primary.main
                    }
                }
            },
            '& > span, & > a': _object_spread_props(_object_spread({
                cursor: 'pointer',
                display: 'flex',
                width: '100%',
                height: '64px',
                alignItems: 'center',
                borderBottom: `${spacing.inner.base} solid transparent`
            }, defaultTransition), {
                '&:hover, &:focus': {
                    borderBottomColor: color.brand.primary.main
                },
                [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                    height: '48px',
                    borderBottom: 0,
                    borderLeft: `${spacing.inner.base} solid transparent`
                }
            }),
            '&.more': {
                '& ul li': {
                    padding: 0
                },
                '& ul li > span, & ul li > a': {
                    borderBottom: 'initial'
                },
                '& ul svg': {
                    position: 'absolute',
                    right: spacing.inner.base03
                },
                '&.hasSubItems': {
                    [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                        padding: 0
                    },
                    '& > ul > li > span': {
                        paddingRight: spacing.inner.base10
                    }
                },
                [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                    display: 'none'
                }
            },
            '& ul': {
                '& a, & span': {
                    display: 'flex',
                    alignItems: 'center',
                    height: '40px',
                    textAlign: 'left',
                    padding: `0 ${spacing.inner.base04}`,
                    [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                        height: '48px',
                        padding: `0 ${spacing.inner.base06} 0 20px`,
                        borderLeft: `${spacing.inner.base} solid transparent`,
                        '&:hover, &:focus': {
                            borderLeftColor: color.base.neutral['100']
                        },
                        '&:active, &:focus': {
                            borderLeftColor: color.base.neutral['100']
                        }
                    }
                }
            },
            [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                '& span.nav-item--highlighted': {
                    fontWeight: 'bold'
                }
            }
        }
    }), {
    name: 'CopanNavigationItem'
});
