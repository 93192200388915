// import LinkExternal from 'public/images/icon.svg';
import type { TagState } from '@loft/react-loft';
import {
  Button,
  Container,
  Dialog,
  Divider,
  Flex,
  Grid,
  GridItem,
  Modal,
  Tag,
  Typography,
  UISpacer,
} from '@loft/react-loft';
import { border, spacing } from '@loft/tokens-loft';
import React from 'react';
import ReactGA from 'react-ga4';
import { TEXT_TAGS } from './constants';

export interface ItemProps {
  title: string;
  content: string;
  tag_state: TagState;
}

export interface BottomComponentProps {
  items: ItemProps[];
}

declare global {
  interface Window {
    RDStationForms: any;
  }
}

const DividerItem: React.FC<ItemProps> = ({ title, content, tag_state }) => (
  <div>
    <Divider />
    <Flex justifyContent="space-between" alignItems="flex-start">
      <Typography textStyle="h3">{title}</Typography>
      <Tag state={tag_state}>{TEXT_TAGS[tag_state]}</Tag>
    </Flex>
    <UISpacer xs="base02" />
    <Typography>{content}</Typography>
    <UISpacer xs="base02" />
  </div>
);

export const BottomComponent: React.FC<BottomComponentProps> = ({ items }) => {
  const [open, setOpen] = React.useState(false);
  const [dialogOpen, setOpenDialog] = React.useState(false);

  React.useEffect(() => {
    window.alert = () => {
      ReactGA.event('completeForms');

      setOpen(false);
      setOpenDialog(true);
    };

    if (window.RDStationForms) {
      new window.RDStationForms('cadastro-ia-loft-618a946c892aa890f865', 'null').createForm();
    }
  });

  const openModal = () => {
    ReactGA.event('openForms');
    setOpen(true);
  };

  const closePopups = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        id="dialog-id"
        open={dialogOpen}
        title=""
        aria-describedby="description-id"
        actions={{
          primary: {
            children: 'Fechar',
            onClick: () => closePopups(),
          },
        }}
      >
        <Typography id="description-id">Cadastro realizado com sucesso!</Typography>
      </Dialog>

      <Modal
        open={open}
        onDismiss={() => setOpen(false)}
        width={480}
        aria-labelledby="Modal de cadastro"
        style={{
          height: 'inherit',
          margin: spacing.layout.base,
        }}
      >
        <div role="main" id="cadastro-ia-loft-618a946c892aa890f865"></div>
      </Modal>

      <Container maxWidth="lg">
        <UISpacer xs="base10" />

        <Grid alignItems="center" justifyItems="center">
          <GridItem xs={12}>
            <Typography textStyle="overline" align="center"></Typography>
            <UISpacer xs="base02" />
            <Typography textStyle="h2" align="center">
              Use AI para transformar desafios em oportunidades.
            </Typography>
            <UISpacer xs="base02" />
            <Typography align="center">
              Com a Loft, sua imobiliária poderá aproveitar diversos modelos de inteligência
              artificial para impulsionar o crescimento do seu negócio e alcançar resultados
              concretos. Conheça algumas das soluções e prepare-se para muitas outras que virão.
              <br />
              <br />
              Junte-se a nós nesta revolução do mercado.
            </Typography>
          </GridItem>

          <GridItem xs={12}>
            <UISpacer xs="base10" />
            <Flex flexDirection="column">
              {items.map((item, index) => (
                <DividerItem
                  key={index}
                  title={item.title}
                  content={item.content}
                  tag_state={item.tag_state}
                />
              ))}

              <Divider />
            </Flex>
            <UISpacer xs="base10" />
          </GridItem>

          <GridItem
            xs={12}
            as="section"
            style={{
              padding: spacing.layout.base03,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              backgroundImage: 'url("/images/masked-header.webp")',
              backgroundSize: 'cover',
              borderRadius: border.radius.md,
            }}
          >
            <UISpacer xs="base02" />

            <Container maxWidth="lg" as="div">
              <Container maxWidth="sm" align="left">
                <Typography textStyle="h2" modifier="inverted">
                  Vamos inovar juntos!
                </Typography>
                <UISpacer xs="base02" />
                <Typography modifier="inverted">
                  Receba mais informações sobre como revolucionar <br />
                  seus negócios com Loft / AI
                </Typography>
                <UISpacer xs="base03" />
                <Button
                  variant="primary"
                  onClick={() => openModal()}
                  aria-expanded={open}
                  aria-haspopup="dialog"
                >
                  Cadastre-se
                </Button>
              </Container>
            </Container>

            <UISpacer xs="base02" />
          </GridItem>
        </Grid>

        <UISpacer xs="base10" />
      </Container>
    </>
  );
};
