import { Container } from '@loft/react-loft';
import { color } from '@loft/tokens-loft';
import React from 'react';
import { ContentBlock } from './ContentBlock';
import { Section } from './Section';

export const BuscaSemantica: React.FC = () => {
  React.useEffect(() => {
    if (window.RDStationForms) {
      new window.RDStationForms(
        'cadastro-assistente-corretor-ia-10190d230f00f733dfd8',
        'null',
      ).createForm();
    }
  });

  return (
    <Section backgroundColor={color.background.secondary}>
      <Container maxWidth="default">
        <ContentBlock
          tag="Ativo"
          title="Cadastre-se e comece a interagir com o Loft/ CRM através do Whatsapp!"
          content={[
            'Busque apartamentos, use a Calculadora Loft, consulte e agende suas próximas visitas usando seu Whatsapp.',
            'Funcionalidade exclusiva para clientes Loft/CRM.',
          ]}
          image="/images/busca-semantica.webp"
          imageAlt="Celular com tela de busca semântica de imóveis"
        >
          <div role="main" id="cadastro-assistente-corretor-ia-10190d230f00f733dfd8"></div>
        </ContentBlock>
      </Container>
    </Section>
  );
};
