import { Header } from '@empire-libs/shared-components';
import { Container, Flex, Typography, UISpacer } from '@loft/react-loft';
import { color } from '@loft/tokens-loft';
import React from 'react';
import { Chat } from './Chat';

export const HeadComponent: React.FC = () => {
  return (
    <div style={{ backgroundColor: color.background.secondary }}>
      <Flex
        flexDirection="column"
        style={{
          backgroundColor: color.background.secondary,
          backgroundImage: 'url("/images/masked-header.webp")',
          backgroundSize: 'cover',
          backgroundPositionX: 'center',
        }}
      >
        <Flex>
          <Header logoHref="" withTransparentBackground withBorder={false} />
        </Flex>

        <Container maxWidth="lg" as="div">
          <Container maxWidth="sm" align="left">
            <UISpacer xs="base" />
            <Typography textStyle="paragraph-lg-bold" style={{ color: color.text.highlight }}>
              IMPULSIONE SEU NEGÓCIO!
            </Typography>
            <UISpacer xs="base02" />
            <Typography textStyle="h2" modifier="inverted">
              Leve sua imobiliária para a próxima geração do mercado
            </Typography>
            <UISpacer xs="base03" />
          </Container>
        </Container>

        <Container maxWidth="lg" as="div">
          <Chat />
        </Container>
      </Flex>
    </div>
  );
};
