import { color } from '@loft/tokens-loft';
import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(
  () => ({
    '@global': {
      '*, *::before, *::after': {
        boxSizing: 'border-box',
      },
      'html, body': {
        height: '100%',
      },
      body: {
        fontSize: '16px',
        textRendering: 'optimizeLegibility',
        '-webkit-font-smoothing': 'antialiased',
        color: color.text.primary,
      },
      img: {
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
      },
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
      a: {
        textDecoration: 'none',
        color: 'inherit',
      },
      '#__next, main': { height: '100%' },
    },
  }),
  {
    name: 'LoftCSSBaseline',
    index: 1,
  },
);

export const ResetStyle = () => {
  useStyles();
  return <></>;
};
