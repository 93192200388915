/* eslint-disable */ import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
export const useToggle = (isOpen = false)=>{
    const [open, setOpen] = useState(isOpen);
    const ref = useRef(null);
    // Binds document event to remove toggle when clicking outside
    useEffect(()=>{
        const onDocumentClick = (e)=>{
            e.stopPropagation();
            if (ref.current && !ref.current.contains(e.target)) setOpen(false);
        };
        document.addEventListener('mousedown', onDocumentClick);
        return ()=>document.removeEventListener('mousedown', onDocumentClick);
    }, []);
    // Create events
    const onToggle = (e)=>{
        e.stopPropagation();
        setOpen(!open);
    };
    const toggledProps = {
        className: clsx({
            opened: open
        })
    };
    const togglerProps = {
        onClick: onToggle,
        onKeyPress: onToggle
    };
    return {
        open,
        setOpen,
        onToggle,
        ref,
        togglerProps,
        toggledProps
    };
};
