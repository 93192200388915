import { screen } from '@loft/copan-tokens';
import { spacing } from '@loft/tokens-loft';
import { createStyles, makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(()=>createStyles({
        accessory: {
            flexGrow: 1,
            [`@media (min-width: ${screen.breakpoint.sm}px)`]: {
                width: 424
            }
        },
        withNavigation: {
            paddingRight: 0,
            [`@media (min-width: ${screen.breakpoint.sm}px)`]: {
                textAlign: 'left',
                paddingRight: spacing.inner.base04,
                flexGrow: 'initial'
            }
        },
        withoutNavigation: {
            marginRight: 0
        },
        hiddenOnXs: {
            display: 'none',
            [`@media (min-width: ${screen.breakpoint.sm}px)`]: {
                display: 'initial'
            }
        }
    }), {
    name: 'CopanHeaderAccessory',
    index: 1
});
