import { screen } from '@loft/copan-tokens';
import { spacing } from '@loft/tokens-loft';
export const defaultTransition = {
    transition: '.2s ease-out'
};
export const distanceBetweenItems = {
    padding: spacing.inner.base04
};
export const navigationItemsBreakpoints = ({ breakpoints  })=>({
        [`@media (min-width: ${screen.breakpoint.lg}px)`]: {
            [`& > ul > li:not(.more):nth-child(n + ${breakpoints.xl + 1})`]: {
                display: 'none',
                [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                    display: 'block'
                }
            }
        },
        [`@media (max-width: ${screen.breakpoint.lg - 1}px)`]: {
            [`& > ul > li:not(.more):nth-child(n + ${breakpoints.lg + 1})`]: {
                display: 'none',
                [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                    display: 'block'
                }
            }
        },
        [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
            [`& > ul > li:not(.more):nth-child(n + ${breakpoints.md + 1})`]: {
                display: 'none',
                [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                    display: 'block'
                }
            }
        }
    });
export const extraNavigationItemsBreakpoints = ({ breakpoints  })=>({
        [`@media (min-width: ${screen.breakpoint.lg}px)`]: {
            [`& > ul > li:nth-child(-n + ${breakpoints.xl})`]: {
                display: 'none'
            }
        },
        [`@media (min-width: ${screen.breakpoint.md}px)`]: {
            [`& > ul > li:nth-child(-n + ${breakpoints.lg})`]: {
                display: 'none'
            }
        },
        [`@media (min-width: ${screen.breakpoint.sm}px)`]: {
            [`& > ul > li:nth-child(-n + ${breakpoints.md})`]: {
                display: 'none'
            }
        },
        [`@media (max-width: ${screen.breakpoint.sm - 1}px)`]: {
            '& > ul > li:nth-child(n)': {
                display: 'block'
            }
        }
    });
export const extraNavigationActiveBreakpoints = ({ moreButtonBreakpoint  })=>moreButtonBreakpoint && moreButtonBreakpoint !== 'xl' && {
        [`@media (min-width: ${screen.breakpoint[moreButtonBreakpoint]}px)`]: {
            '&.more': {
                display: 'none'
            }
        }
    };
