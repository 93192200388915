import { Container, Flex, Image, UISpacer } from '@loft/react-loft';
import { color } from '@loft/tokens-loft';
import React from 'react';
import { ContentBlock } from './ContentBlock';
import { Section } from './Section';

export const Plim: React.FC = () => (
  <Section backgroundColor={color.background.secondary}>
    <Container maxWidth="default">
      <ContentBlock
        tag="Ativo"
        title="Otimize a eficiência dos seus anúncios sem esforço"
        content={[
          'Utilize nossas soluções de IA para melhorar automaticamente a descrição dos imóveis e definir preços com mais precisão, garantindo um desempenho superior dos seus anúncios.',
        ]}
        image="images/plim.webp"
        imageAlt="Smartphone com editor de anúncios"
      />

      <UISpacer xs="base04" md="base10" />

      <Flex centered>
        <Image src="images/plim-ipad.webp" width="750px"></Image>
      </Flex>
    </Container>
  </Section>
);
