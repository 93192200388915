import _object_spread from "@swc/helpers/src/_object_spread.mjs";
class IntlRegistryImpl {
    register(msgs) {
        if (this.subscribers.length > 0) this.subscribers.forEach((fn)=>fn(msgs));
        this.messages = _object_spread({}, this.messages, msgs);
    }
    collect() {
        return this.messages;
    }
    subscribe(fn) {
        this.subscribers.push(fn);
        return ()=>{
            this.subscribers = this.subscribers.filter((it)=>it !== fn);
        };
    }
    constructor(){
        this.messages = {};
        this.subscribers = [];
    }
}
export const IntlRegistry = new IntlRegistryImpl();
export const registerLang = (messages)=>{
    IntlRegistry.register(messages);
};
