import { Flex, Grid, GridItem, Image, Tag, Typography, UISpacer } from '@loft/react-loft';
import React from 'react';

interface ContentBlockProps {
  tag: string;
  title: string;
  subtitle?: string;
  content: string[];
  image?: string;
  imageAlt?: string;
  reverse?: boolean;
  children?: React.ReactNode;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  tag,
  title,
  subtitle,
  content,
  image,
  imageAlt,
  reverse,
  children,
}) => (
  <Grid alignItems="center" justifyItems="center">
    <GridItem xs={12} md={6} style={{ order: reverse ? 2 : 1 }}>
      {reverse && <UISpacer xs="base02" />}

      <Flex flexDirection="column" alignItems="flex-start" justifyContent="center">
        <Tag state="positive">{tag}</Tag>
        <UISpacer xs="base" />
        <Typography textStyle="h2">{title}</Typography>
        {subtitle && <Typography textStyle="subtitle">{subtitle}</Typography>}
        <UISpacer xs="base03" />
        {content.map((text, index) => (
          <React.Fragment key={index}>
            <Typography>{text}</Typography>
            {index < content.length - 1 && <UISpacer xs="base" />}
          </React.Fragment>
        ))}
      </Flex>

      <div>
        <UISpacer xs="base03" />
        {children}
      </div>
    </GridItem>

    <GridItem xs={12} md={6} style={{ order: reverse ? 1 : 2 }}>
      {!reverse && <UISpacer xs="base02" />}
      {/* {image && <SmartphoneMockup image={image} imageAlt={imageAlt || ''} />} */}
      {image && <Image src={image} alt={imageAlt || ''} width="350px"></Image>}
    </GridItem>
  </Grid>
);
