import { color, screen } from '@loft/copan-tokens';
import { spacing } from '@loft/tokens-loft';
import { createStyles, makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(()=>createStyles({
        topPortion: {
            height: 56,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: `0 ${spacing.inner.base06}`,
            [`@media (min-width: ${screen.breakpoint.sm}px)`]: {
                justifyContent: 'flex-end',
                padding: `0 ${spacing.inner.base10}`,
                '& > svg': {
                    display: 'none'
                },
                '& > a > svg': {
                    display: 'none'
                }
            }
        },
        toggleMenuIcon: {
            background: 'none',
            border: 'none',
            width: 40,
            height: 40,
            padding: 0,
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: `-${spacing.inner.base02}`,
            '& svg': {
                cursor: 'pointer'
            },
            '&:active': {
                outline: 'none'
            },
            '&:focus': {
                outline: 'none'
            }
        },
        navigation: {
            '& > ul': {
                justifyContent: 'flex-end'
            }
        },
        divider: {
            height: 1,
            border: 0,
            background: color.base.neutral['200'],
            margin: `${spacing.inner.base06} 0`
        }
    }), {
    name: 'CopanHeaderNavigation',
    index: 1
});
