/* eslint-disable react/jsx-props-no-spreading */

import { LangProvider } from '@empire-libs/intl';
import { CopanProvider } from '@loft/copan-components';
import { StylesSSR } from '@loft/react-loft';
import { logo } from '@loft/tokens-loft';
import { CssBaseline } from '@material-ui/core';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';
import ReactGA from 'react-ga4';
import { BottomComponent } from '@/components/BottomComponent';
import { BuscaSemantica } from '@/components/BuscaSemantica';
import { ITEMS_BOTTOM_COMPONENT } from '@/components/constants';
import { HeadComponent } from '@/components/HeadComponent';
import { Plim } from '@/components/Plim';
import { SimuladorFinanciamento } from '@/components/SimuladorFinanciamento';
import defaultMessages from '@/lang/pt';
import { ResetStyle } from '@/theme/resetStyle';

function CustomApp({ Component, pageProps }: AppProps) {
  ReactGA.initialize('G-VGJ9BVPZ2R');

  ReactGA.send({
    hitType: 'pageview',
    page: '/',
    title: 'Home',
  });

  return (
    <LangProvider locale="pt" defaultMessages={defaultMessages}>
      <Head>
        <link rel="icon" type="image/svg" href={logo.primary.small.svg} />
        <script
          async
          type="text/javascript"
          src="https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js"
        ></script>
        <title>Loft AI</title>
      </Head>
      <StylesSSR />
      <CopanProvider>
        <CssBaseline />
        <ResetStyle />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://content.loft.com.br/copan/loft/webfonts/font.css"
        />
        <Component {...pageProps} />
        <HeadComponent />
        <div id="assistente-corretor">
          <BuscaSemantica />
        </div>
        <SimuladorFinanciamento />
        <Plim />
        <BottomComponent items={ITEMS_BOTTOM_COMPONENT} />
      </CopanProvider>
    </LangProvider>
  );
}

export default CustomApp;
