import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { color, screen } from '@loft/copan-tokens';
import { spacing } from '@loft/tokens-loft';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { defaultTransition, distanceBetweenItems, extraNavigationActiveBreakpoints, extraNavigationItemsBreakpoints, navigationItemsBreakpoints } from './Base.styles';
export const useStyles = (props)=>makeStyles(()=>createStyles({
            navigation: _object_spread_props(_object_spread({
                color: color.text.primary,
                display: 'flex',
                '& ul': {
                    padding: 0,
                    listStyle: 'none'
                }
            }, navigationItemsBreakpoints(props)), {
                [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                    width: '100%'
                }
            }),
            // General menu
            baseList: {
                margin: 0,
                display: 'flex',
                width: '100%',
                '& > li': {
                    textAlign: 'center',
                    position: 'relative',
                    '&.hasSubItems': {
                        paddingRight: distanceBetweenItems.padding,
                        [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                            paddingRight: 0
                        }
                    },
                    [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                        padding: 0
                    }
                },
                [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                    flexDirection: 'column'
                },
                // Applies position by right at last two elements of base list
                '& > li:last-child ul, & > li:nth-last-child(2) ul': {
                    right: 0,
                    left: 'initial'
                },
                '& > li > ul': {
                    minWidth: '100%'
                }
            },
            // More items
            extraNavigation: _object_spread({
                '& li.hasSubItems > ul': {
                    top: `-${spacing.inner.base02}`,
                    right: '100%'
                },
                '& li': {
                    position: 'relative',
                    '& span': _object_spread({}, defaultTransition)
                },
                '& .hasSubItems span': {
                    '&:after': {
                        right: spacing.inner.base02
                    }
                }
            }, extraNavigationItemsBreakpoints(props), extraNavigationActiveBreakpoints(props))
        }), {
        name: 'CopanNavigation'
    });
