import { color, spacing } from '@loft/tokens-loft';
import React from 'react';

interface SectionProps {
  children: React.ReactNode;
  backgroundColor?: string;
  minHeight?: string;
}

export const Section: React.FC<SectionProps> = ({ children, backgroundColor, minHeight }) => (
  <div
    style={{
      backgroundColor: backgroundColor || color.background.primary,
      minHeight: minHeight || 'auto',
      paddingTop: spacing.layout.base10,
    }}
  >
    {children}
  </div>
);
