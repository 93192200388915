import { Container } from '@loft/react-loft';
import React from 'react';
import { ContentBlock } from './ContentBlock';
import { Section } from './Section';

export const SimuladorFinanciamento: React.FC = () => (
  <Section>
    <Container maxWidth="default">
      <ContentBlock
        tag="Ativo"
        title="Simule financiamento imobiliário em poucos segundos"
        subtitle="Nossa ferramenta gratuita está disponível para mais de 700 imobiliárias e responde a consultas ilimitadas em menos de 15 segundos via WhatsApp."
        content={[
          'O assistente virtual do corretor de imóveis realiza simulações por texto ou áudio com base no valor a ser financiado.',
          'A simulação apresenta um comparativo das condições de diversos bancos, incluindo prazo, renda mínima, amortização, valor da primeira parcela, taxa de juros e custo efetivo total do financiamento.',
        ]}
        image="images/simulador.webp"
        imageAlt="Simulador de financiamento no whatsapp"
        reverse
      />
    </Container>
  </Section>
);
