import { color, screen } from '@loft/copan-tokens';
import { spacing } from '@loft/tokens-loft';
import { createStyles, makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(()=>createStyles({
        root: {
            background: color.background.light,
            height: 56,
            display: 'flex',
            position: 'static',
            [`@media (min-width: ${screen.breakpoint.md}px)`]: {
                height: 64
            }
        },
        fixedHeader: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 2
        },
        withTransparentBackground: {
            backgroundColor: 'transparent'
        },
        withBorder: {
            borderBottom: '1px solid',
            borderBottomColor: color.separator.light
        },
        logoContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        logo: {
            marginRight: spacing.layout.base02,
            maxWidth: '32px',
            lineHeight: 0,
            [`@media (min-width: ${screen.breakpoint.sm}px)`]: {
                maxWidth: '40px'
            }
        },
        navigation: {
            marginLeft: 0,
            [`@media (min-width: ${screen.breakpoint.md}px)`]: {
                marginLeft: spacing.inner.base04
            }
        },
        navigationWithoutAccessory: {
            flexGrow: 1
        },
        navigationWithAccessory: {
            flexGrow: 'initial',
            [`@media (min-width: ${screen.breakpoint.sm}px)`]: {
                flexGrow: 1
            }
        },
        extraComponent: {
            marginLeft: spacing.inner.base04,
            marginRight: `-${spacing.inner.base02}`,
            display: 'none',
            [`@media (min-width: ${screen.breakpoint.md}px)`]: {
                display: 'initial'
            }
        },
        navigationDesktop: {
            display: 'none',
            marginRight: `-${spacing.inner.base04}`,
            [`@media (min-width: ${screen.breakpoint.md}px)`]: {
                display: 'initial'
            }
        },
        productTitle: {
            color: color.base.green[800]
        }
    }), {
    name: 'CopanHeader',
    index: 1
});
