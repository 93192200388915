import { ArrowRightIcon, ChatIcon } from '@loft/react-icons';
import {
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  TextField,
  Typography,
  UISpacer,
} from '@loft/react-loft';
import { border, color, elevation, logo, spacing } from '@loft/tokens-loft';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import Markdown from 'react-markdown';
import { v4 as uuidv4 } from 'uuid';

interface Message {
  id: string;
  text: string;
  isUser: boolean;
}

interface FormValues {
  message: string;
}

const fixedSuggestions = [
  'O que é a Loft?',
  'O que a Loft faz?',
  'Quais produtos a Loft oferece?',
  'Como a Loft usa AI?',
];

const ChatBubble: React.FC<{ message: Message }> = ({ message }) => (
  <>
    {message.isUser ? (
      <Grid
        alignItems="center"
        style={{
          padding: spacing.inner.base02,
          backgroundColor: color.background.primary,
          borderColor: color.separator.light,
          borderWidth: 'thin',
          borderStyle: 'solid',
          borderRadius: border.radius.sm,
        }}
      >
        <GridItem size={1}>
          <div
            style={{
              width: '12px',
              height: '12px',
              borderRadius: border.radius.circle,
              backgroundColor: color.decorative.green.main,
            }}
          />
        </GridItem>
        <GridItem size={11}>
          <Typography>
            <Markdown>{message.text}</Markdown>
          </Typography>
        </GridItem>
      </Grid>
    ) : (
      <Grid
        alignItems="start"
        style={{
          padding: spacing.inner.base04,
          borderRadius: border.radius.sm,
          backgroundColor: color.background.secondary,
        }}
      >
        <GridItem size={1}>
          <Image src={logo.primary.key.svg} width="20" height="20" />
        </GridItem>
        <GridItem size={11}>
          <Typography textStyle="caption" variant="secondary">
            Loft AI
          </Typography>
        </GridItem>
        <GridItem size={1} />
        <GridItem size={11}>
          <Typography>
            <Markdown>{message.text}</Markdown>
          </Typography>
        </GridItem>
      </Grid>
    )}
  </>
);

export const Chat: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [session_id] = useState(uuidv4());

  const scrollToBottom = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const onSubmitMessage = async (values: FormValues, { resetForm }: { resetForm: () => void }) => {
    if (!values.message.trim()) return;

    const newMessage: Message = {
      id: uuidv4(),
      text: values.message,
      isUser: true,
    };

    ReactGA.event('chat', newMessage);
    setMessages(prevMessages => [...prevMessages, newMessage]);
    resetForm();

    try {
      const response = await fetch('/api/bot-response', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ session_id, message: values.message }),
      });

      if (!response.ok) {
        throw new Error('Failed to get bot response');
      }
      const data = await response.json();
      const botMessage: Message = {
        id: uuidv4(),
        text: data.response,
        isUser: false,
      };
      setMessages(prevMessages => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Error getting bot response:', error);
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    onSubmitMessage({ message: suggestion }, { resetForm: () => {} });
  };

  const initialState = messages.length === 0;

  return (
    <Container
      as="div"
      style={{
        padding: spacing.inner.base05,
        boxShadow: elevation.md,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: color.separator.light,
        backgroundColor: color.background.secondary,
        borderRadius: border.radius.sm,
        height: '55vh',
      }}
    >
      <div
        style={{
          backgroundColor: color.background.primary,
          borderRadius: border.radius.sm,
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          borderColor: color.separator.light,
          borderStyle: 'solid',
          borderWidth: 1,
        }}
      >
        <div style={{ flex: 1, overflowY: 'auto' }} ref={scrollableRef}>
          {initialState ? (
            <Grid
              alignItems="center"
              gap="base02"
              style={{ height: '100%', padding: `${spacing.inner.base} ${spacing.layout.base02}` }}
            >
              <GridItem size={12}>
                <Typography textStyle="h3" modifier="highlight">
                  Bem vindo ao Loft AI
                </Typography>
                <UISpacer xs="base02" />
                <Typography
                  align="justify"
                  style={{
                    display: 'flex',
                    gap: spacing.inner.base02,
                  }}
                >
                  <ChatIcon />
                  Comece com alguma pergunta mais usada ou faça a sua própria
                </Typography>
              </GridItem>

              <GridItem size={12}>
                <ul
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                    gridGap: spacing.layout.base02,
                    listStyleType: 'none',
                    padding: 0,
                  }}
                >
                  {fixedSuggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                      style={{
                        cursor: 'pointer',
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderColor: color.separator.light,
                        borderRadius: border.radius.sm,
                        height: `80px`,
                        padding: spacing.inner.base02,
                      }}
                    >
                      <Typography variant="secondary">{suggestion}</Typography>
                    </li>
                  ))}
                </ul>
              </GridItem>
              <GridItem size={12}>
                <UISpacer />
              </GridItem>
            </Grid>
          ) : (
            <Flex flexDirection="column" gap="innerBase04" m="innerBase02" p="innerBase04">
              {messages.map(message => (
                <ChatBubble key={message.id} message={message} />
              ))}
            </Flex>
          )}
        </div>

        <div style={{ padding: spacing.layout.base02 }}>
          <Formik initialValues={{ message: '' }} onSubmit={onSubmitMessage}>
            {({ submitForm }) => (
              <Form>
                <Field name="message">
                  {({ field }) => (
                    <TextField
                      {...field}
                      id="chat-message"
                      aria-label="Pergunta"
                      placeholder="Escreva uma pergunta..."
                      fullWidth
                      addonAfter={
                        <Button contextual icon={<ArrowRightIcon />} onClick={submitForm}></Button>
                      }
                    />
                  )}
                </Field>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Container>
  );
};
