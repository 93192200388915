import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { color, screen, typography } from '@loft/copan-tokens';
import { spacing } from '@loft/tokens-loft';
import { createStyles, makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(()=>createStyles({
        profile: {
            position: 'relative',
            '& .hasSubItems ul': {
                minWidth: '100%',
                right: 0,
                left: 'auto',
                [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                    '&:not(.opened)': {
                        opacity: '1',
                        maxHeight: '100vh',
                        visibility: 'visible',
                        position: 'initial',
                        '& li a': _object_spread_props(_object_spread({
                            background: color.background.light
                        }, typography.label.md), {
                            padding: `0 ${spacing.inner.base06}`,
                            '&:hover': {
                                borderLeftColor: color.base.neutral['200']
                            }
                        })
                    }
                }
            },
            '& > li > span': {
                [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                    height: 'auto',
                    pointerEvents: 'none'
                }
            },
            [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                '& svg': {
                    display: 'none'
                },
                '& li': {
                    padding: 0,
                    '& > span': {
                        borderLeft: 0
                    }
                },
                '& + *': {
                    borderTop: `1px solid ${color.base.neutral['200']}`
                }
            }
        },
        info: {
            display: 'flex',
            alignItems: 'center',
            [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                padding: `${spacing.inner.base04} 0`
            }
        },
        avatar: {
            marginRight: spacing.inner.base02,
            '&:last-child': {
                display: 'none'
            },
            [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: {
                '&:first-child': {
                    display: 'none'
                },
                '&:last-child': {
                    display: 'flex'
                }
            }
        },
        name: _object_spread_props(_object_spread({}, typography.paragraph.md), {
            [`@media (max-width: ${screen.breakpoint.md - 1}px)`]: _object_spread({}, typography.subtitle)
        }),
        email: _object_spread_props(_object_spread({
            color: color.text.secondary
        }, typography.paragraph.md), {
            [`@media (min-width: ${screen.breakpoint.md}px)`]: {
                display: 'none'
            }
        })
    }), {
    name: 'CopanProfile'
});
