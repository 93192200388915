import type { ItemProps } from './BottomComponent';

export const TEXT_TAGS = {
  neutral: 'VEM AÍ',
  positive: 'Ativo',
};

export const ITEMS_BOTTOM_COMPONENT: ItemProps[] = [
  {
    title: 'Jornada ponta a ponta de financiamento',
    content:
      'Garantia de agilidade no processo de análise de documentos para solicitações de financiamento, com o processamento de documentos enviados pelo cliente e notificação de possíveis pendências que podem impactar o processo.',
    tag_state: 'neutral',
  },
  {
    title: 'Qualificação de Leads',
    content:
      'Auxílio na categorização de novos leads dentro da imobiliária, a fim de qualificar melhor os perfis dos clientes e servi-los da melhor maneira dentro da jornada',
    tag_state: 'neutral',
  },
  {
    title: 'Assistente para corretores',
    content:
      'Suporte inteligente aos corretores em etapas de agendamento de visita, pós-visita e recomendações de imóveis durante a fase de busca pelos inquilinos pelo imóvel ideal.',
    tag_state: 'neutral',
  },

  {
    title: 'Gestão de Portfolio',
    content:
      'Tenha visibilidade de áreas em que sua imobiliária pode ter como foco para expandir portfólio na região, bem como melhorar a qualidade de anúncio dos imóveis existentes na base, a fim de aumentar vendas.',
    tag_state: 'neutral',
  },
  {
    title: 'Assistente Loft',
    content:
      'Centralização do atendimento Loft, com tudo que sua imobiliária pode precisar de ajuda e suporte, a poucos cliques de distância em um único canal.',
    tag_state: 'neutral',
  },
];
